import jwtDecode from "jwt-decode";
import { jwtTokenType } from "../types/jwt";

export function decodeToken(token?: string | null): null | jwtTokenType {
    if (token) {
        try {
            return jwtDecode(token) as jwtTokenType;
        } catch (e) {
            return null;
        }
    }
    return null;
}
