import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { jwtTokenType } from "../types/jwt";
import { decodeToken } from "../lib/decode";

const AppContext = createContext<{
    user: [
        user: jwtTokenType | null,
        setUser: Dispatch<SetStateAction<jwtTokenType | null>>
    ];
    width: [width: number, setWidth: Dispatch<SetStateAction<number>>];
    isSmallScreen: boolean;
    // @ts-ignore
}>(null);

export default function AppProvider(props: { children: JSX.Element | JSX.Element[] }) {
    const [user, setUser] = useState<jwtTokenType | null>(
        decodeToken(localStorage.getItem("token"))
    );

    const [width, setWidth] = useState(window.innerWidth);
    const isSmallScreen = width < 700;

    useEffect(() => {
        window.addEventListener("resize", () => {
            width !== window.innerWidth && setWidth(window.innerWidth);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider
            value={{
                user: [user, setUser],
                width: [width, setWidth],
                isSmallScreen,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export function useUser() {
    const { user } = useContext(AppContext);
    return user;
}

export function useWidth() {
    const { width } = useContext(AppContext);
    return width;
}

export function useIsSmallScreen() {
    const { isSmallScreen } = useContext(AppContext);
    return isSmallScreen;
}
