import {
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { api } from "../lib/api";
import humanizeDuration from "humanize-duration";
import { SentimentDissatisfied } from "@mui/icons-material";
import { useIsSmallScreen } from "../context/AppContext";

type result = { time: number; unsolved: number; id: number };

export default function Rankings() {
    const [data, setData] = useState<
        { best: result; email: string; class_name: string; class_number: string }[]
    >([]);
    const [noPlayers, setNoPlayers] = useState(false);

    const isSmallScreen = useIsSmallScreen();

    useEffect(() => {
        api.get("/rank").then((res) => {
            if (!res.data.length) return setNoPlayers(true);
            setData(res.data);
        });
    }, []);

    return (
        <Box className={"flex flex-dir-column align-center"}>
            <h1 className={"text-decoration-underline"}>Rankings</h1>
            {data.length ? (
                <TableContainer
                    component={Paper}
                    sx={{ width: isSmallScreen ? "90vw" : "60vw" }}
                >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Rank</TableCell>
                                <TableCell>Class</TableCell>
                                <TableCell>Class No.</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Unsolved</TableCell>
                                <TableCell>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow
                                    key={row.email}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row.class_name}</TableCell>
                                    <TableCell>{row.class_number}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    <TableCell>{row.best.unsolved}</TableCell>
                                    <TableCell>
                                        {humanizeDuration(row.best.time, { round: true })}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : noPlayers ? (
                <Typography className={"flex align-center font-size-20-force"}>
                    No one has played yet <SentimentDissatisfied className={"ml5"} />
                </Typography>
            ) : (
                <CircularProgress />
            )}
        </Box>
    );
}
