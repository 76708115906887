import { Route, Routes as Switch } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/users/Signin";
import Register from "./pages/users/Register";
import Rank from "./pages/Rankings";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" element={<Home />} />
            <Route path="/users/signin" element={<SignIn />} />
            <Route path="/users/register" element={<Register />} />
            <Route path={"/rank"} element={<Rank />} />
        </Switch>
    );
}
