import { Box, Button } from "@mui/material";
import Puzzle from "../components/Puzzle";
import { useUser } from "../context/AppContext";
import { Navigate } from "react-router-dom";
import { useState } from "react";

export default function Home() {
    const [user] = useUser();
    const [start, setStart] = useState(false);

    if (!user) return <Navigate to="/users/signin" />;

    return (
        <Box className={"flex flex-dir-column align-center width-fullvw mt3 mb40"}>
            <h1 className={"text-decoration-underline"}>Cross-word Puzzle</h1>
            {!start && (
                <Button variant={"contained"} onClick={() => setStart(true)}>
                    Start
                </Button>
            )}
            {start && <Puzzle />}
        </Box>
    );
}
