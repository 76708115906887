import {
    CrosswordGrid,
    CrosswordProvider,
    CrosswordProviderImperative,
    DirectionClues,
    ThemeProvider,
} from "@jaredreisinger/react-crossword";
import { CluesInputOriginal } from "@jaredreisinger/react-crossword/dist/types";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
} from "@mui/material";
import {
    Cancel,
    Done,
    SentimentVeryDissatisfied,
    Timer as TimerIcon,
} from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import { api } from "../lib/api";
import { useNavigate } from "react-router-dom";
import Timer from "react-compound-timerv2";
import { useIsSmallScreen } from "../context/AppContext";

export default function Puzzle() {
    const start = useRef(new Date());
    const crossword = useRef<CrosswordProviderImperative>(null);

    const [puzzle, setPuzzle] = useState<{
        data: CluesInputOriginal;
        topic: string;
        id: number;
    } | null>(null);

    const totalWords = puzzle
        ? Object.keys(puzzle.data.down).length + Object.keys(puzzle.data.across).length
        : 0;

    const [loading, setLoading] = useState(false);
    const [noPuzzles, setNoPuzzles] = useState(false);
    const [solvedWords, setSolvedWords] = useState<string[]>([]);
    const unsolved = totalWords - solvedWords.length;
    const [dialog, setDialog] = useState<{
        open: boolean;
        message: string;
        title: string;
        btns?: { action: () => void; text: string }[];
        onClose?: () => void;
    }>({
        open: false,
        message: "",
        title: "",
    });
    const [openFinishedDialog, setOpenFinishedDialog] = useState(false);
    const [finished, setFinished] = useState(false);

    const navigate = useNavigate();
    const isSmallScreen = useIsSmallScreen();

    const postResult = useCallback(() => {
        if (!finished) {
            setFinished(true);

            const time = new Date().getTime() - start.current.getTime();

            api.post("/result", {
                time,
                unsolved,
                id: puzzle?.id,
            })
                .then(() => {
                    setOpenFinishedDialog(true);
                })
                .catch((err) => {
                    setFinished(false);
                    setDialog({
                        open: true,
                        message:
                            err?.response?.data?.error ||
                            err?.response?.data ||
                            err?.message ||
                            "Something went wrong",
                        title: "Error",
                    });
                });
        }
    }, [finished, puzzle?.id, unsolved]);

    useEffect(() => {
        setLoading(true);
        api.get("/puzzle")
            .then(({ data }) => {
                setLoading(false);
                const { puzzle, topic, id } = data;
                if (puzzle && topic && id) {
                    setPuzzle({ data: puzzle, topic, id });
                } else {
                    setNoPuzzles(true);
                }
            })
            .catch(() => {
                setLoading(false);
                setDialog({
                    open: true,
                    message: "There was an error loading the puzzle.",
                    title: "Error",
                });
            });
    }, []);

    useEffect(() => {
        if (totalWords && !unsolved && !finished) postResult();
    }, [finished, postResult, puzzle, solvedWords.length, totalWords, unsolved]);

    useEffect(() => {
        if (openFinishedDialog && !dialog.open) {
            const btns = [
                {
                    action: () => {
                        window.location.href = "/";
                    },
                    text: "Play another",
                },
                {
                    action: () => navigate("/rank"),
                    text: "See rankings",
                },
            ];
            const onClose = () => navigate("/rank");
            if (!unsolved) {
                setDialog({
                    open: true,
                    message: "You solved the puzzle!",
                    title: "Congratulations!",
                    btns,
                    onClose,
                });
            } else {
                setDialog({
                    open: true,
                    message: `You solved ${solvedWords.length} words!`,
                    title: "Result",
                    btns: [
                        ...btns,
                        {
                            action: () => {
                                setDialog({
                                    open: false,
                                    message: "",
                                    title: "",
                                });
                                setOpenFinishedDialog(false);
                                crossword.current && crossword.current.fillAllAnswers();
                            },
                            text: "View answers",
                        },
                    ],
                });
            }
        }
    }, [openFinishedDialog, dialog.open, unsolved, navigate, solvedWords.length]);

    const status = React.useMemo(() => {
        const statusBoxes = [
            {
                icon: <Done className={"mr10"} />,
                content: `${solvedWords.length} solved`,
            },
            {
                icon: <Cancel className={"mr10"} />,
                content: `${unsolved} unsolved`,
            },
            {
                icon: <TimerIcon className={"mr10"} />,
                content: (
                    <Timer
                        formatValue={(v) => {
                            const s = String(v);
                            if (s.length === 1) return `0${s}`;
                            return s;
                        }}
                    >
                        {(control: { stop: () => void }) => {
                            finished && control.stop();
                            return (
                                <React.Fragment>
                                    <Timer.Minutes />:<Timer.Seconds />
                                </React.Fragment>
                            );
                        }}
                    </Timer>
                ),
            },
        ];
        return (
            <Paper className={"flex"}>
                {statusBoxes.map((box) => (
                    <Box className={"flex align-center p15"}>
                        {box.icon}
                        {box.content}
                    </Box>
                ))}
            </Paper>
        );
    }, [finished, solvedWords.length, unsolved]);

    const CrossWord = React.useMemo(
        () =>
            puzzle && (
                <ThemeProvider
                    theme={{ focusBackground: red[300], highlightBackground: red[100] }}
                >
                    <CrosswordProvider
                        ref={crossword}
                        useStorage={false}
                        data={puzzle.data}
                        onCorrect={(direction, number, answer) => {
                            if (!solvedWords.includes(answer) && !finished) {
                                setSolvedWords((solved) => [...solved, answer]);
                                setDialog({
                                    open: true,
                                    message: `You got ${answer}!`,
                                    title: "Correct answer",
                                });
                            }
                        }}
                    >
                        <div style={widthStyles}>
                            <CrosswordGrid theme={{}} />
                        </div>
                        <Box className={isSmallScreen ? "" : "flex"}>
                            <div className={isSmallScreen ? "ml10 mr10" : "mr40"}>
                                <DirectionClues label={"Across"} direction="across" />
                            </div>
                            <div className={isSmallScreen ? "ml10 mr10" : ""}>
                                <DirectionClues label={"Down"} direction="down" />
                            </div>
                        </Box>
                    </CrosswordProvider>
                </ThemeProvider>
            ),
        [puzzle, isSmallScreen, solvedWords, finished]
    );

    const Body = React.useMemo(() => {
        const closeDialog = () => {
            setDialog({ ...dialog, open: false });
        };
        return (
            <React.Fragment>
                {puzzle && (
                    <Alert severity="info" className={"halfwidth mb20"} sx={widthStyles}>
                        Hint: The topic is {puzzle.topic}.
                    </Alert>
                )}
                <Dialog
                    open={dialog.open}
                    fullWidth
                    onClose={dialog.onClose || closeDialog}
                    onKeyDown={(e) => {
                        if (e.key === "Escape" || e.key === "Enter") closeDialog();
                    }}
                >
                    <DialogTitle>{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{dialog.message}</DialogContentText>
                        <DialogActions>
                            {dialog.btns?.length ? (
                                dialog.btns.map((btn) => (
                                    <Button
                                        onClick={() => {
                                            btn.action();
                                            closeDialog();
                                        }}
                                    >
                                        {btn.text}
                                    </Button>
                                ))
                            ) : (
                                <Button onClick={closeDialog}>OK</Button>
                            )}
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Box className={"flex flex-dir-column align-center"}>
                    <Box
                        className={`mb20 ${
                            isSmallScreen ? "" : "flex align-center justify-space-between"
                        }`}
                        sx={widthStyles}
                    >
                        <Button
                            variant={"outlined"}
                            onClick={postResult}
                            disabled={finished}
                            className={window.innerWidth > 700 ? "" : "mb20"}
                        >
                            <SentimentVeryDissatisfied className={"mr5"} />
                            Give Up
                        </Button>
                        {status}
                    </Box>
                    {CrossWord}
                </Box>
            </React.Fragment>
        );
    }, [puzzle, dialog, postResult, finished, status, CrossWord, isSmallScreen]);

    return (
        <React.Fragment>
            {noPuzzles && (
                <Alert severity="warning" className={"halfwidth mb20"} sx={widthStyles}>
                    You have already solved all of the puzzles. Thank you for playing!
                </Alert>
            )}
            {loading && <CircularProgress />}
            {puzzle && Body}
        </React.Fragment>
    );
}

const widthStyles = { width: "90vw", maxWidth: "60em" };
