import "./App.css";
import "metahkg-css/dist/margin.min.css";
import "metahkg-css/dist/padding.min.css";
import "metahkg-css/dist/fontsize.min.css";
import React from "react";
import Routes from "./routes";
import ResponsiveAppBar from "./components/AppBar";
import { BrowserRouter as Router } from "react-router-dom";
import { Box } from "@mui/material";
import Theme from "./components/theme";

export default function App() {
    return (
        <Theme>
            <Box className="App">
                <Router>
                    <ResponsiveAppBar />
                    <Box sx={{ overflow: "auto", maxHeight: "calc(100vh - 56px)" }}>
                        <Routes />
                    </Box>
                </Router>
            </Box>
        </Theme>
    );
}
