import { createTheme, ThemeProvider } from "@mui/material";
import { red } from "@mui/material/colors";

declare module "@mui/material/styles" {
    interface Theme {
        status: {
            danger: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

export default function Theme(props: { children: JSX.Element | JSX.Element[] }) {
    const { children } = props;
    const theme = createTheme({
        palette: {
            primary: {
                main: red[300],
            },
            secondary: {
                main: "#fff",
            },
        },
        typography: {
            fontFamily: "Arial",
        },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
