import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import { api } from "../../lib/api";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useIsSmallScreen, useUser } from "../../context/AppContext";
import { decodeToken } from "../../lib/decode";
import { TextFieldProps } from "@mui/material/TextField/TextField";

export default function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useUser();

    const navigate = useNavigate();
    const isSmallScreen = useIsSmallScreen();

    if (user) return <Navigate to={"/"} />;

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        if (!email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@cloud.qes.edu.hk$/)) {
            setError("Invalid email address. Email must end with cloud.qes.edu.hk.");
            setLoading(false);
            return;
        }

        try {
            const { token } = (
                await api.post("/users/signin", {
                    email,
                    password,
                })
            ).data as { token: string; success: boolean };

            if (token) {
                localStorage.setItem("token", token);
                setUser(decodeToken(token));
                navigate("/");
            }

            setEmail("");
            setPassword("");
        } catch (error: any) {
            setError(
                error?.response?.data?.error ||
                    error?.response?.data ||
                    error?.message ||
                    "Unknown error"
            );
        }
        setLoading(false);
    };

    const inputProps: TextFieldProps[] = [
        {
            label: "Email",
            onChange: (e) => setEmail(e.target.value),
            type: "email",
            autoComplete: "email",
            inputProps: { format: "[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@cloud.qes.edu.hk" },
            helperText: !email.match(
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@cloud.qes.edu.hk$/
            )
                ? "Email must end with cloud.qes.edu.hk"
                : "",
        },
        {
            label: "Password",
            onChange: (e) => setPassword(e.target.value),
            type: "password",
            autoComplete: "current-password",
            helperText: !password ? "Password must not be empty" : "",
        },
    ];

    return (
        <Box className={"flex align-center flex-dir-column mb40"}>
            <form
                onSubmit={handleSubmit}
                className={"flex flex-dir-column"}
                style={{ width: isSmallScreen ? "80vw" : "50vw" }}
            >
                <h2>Sign in</h2>
                {error && (
                    <Alert className={"mb20"} severity="error">
                        {error}
                    </Alert>
                )}
                {inputProps.map((props, index) => (
                    <TextField
                        key={index}
                        {...props}
                        className={"mb20"}
                        error={!!error}
                        fullWidth
                        variant="outlined"
                        disabled={loading}
                        required
                    />
                ))}
                <div className="flex justify-space-between align-center">
                    <Link className={"text-decoration-none"} to={"/users/register"}>
                        <Button variant={"text"}>Register</Button>
                    </Link>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button type="submit" variant={"contained"}>
                            Sign in
                        </Button>
                    )}
                </div>
            </form>
        </Box>
    );
}
