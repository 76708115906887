import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import { api } from "../../lib/api";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useIsSmallScreen, useUser } from "../../context/AppContext";
import { decodeToken } from "../../lib/decode";
import { TextFieldProps } from "@mui/material/TextField/TextField";

export default function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [class_name, setClassName] = useState("");
    const [class_number, setClassNumber] = useState(0);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useUser();

    const navigate = useNavigate();
    const isSmallScreen = useIsSmallScreen();

    if (user) return <Navigate to="/" />;

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        if (!email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@cloud.qes.edu.hk$/)) {
            setError("Invalid email address. Email must end with cloud.qes.edu.hk.");
            setLoading(false);
            return;
        }

        try {
            const { token } = (
                await api.post("/users/register", {
                    email,
                    password,
                    class_name,
                    class_number,
                })
            ).data as { token: string; success: boolean };

            if (token) {
                localStorage.setItem("token", token);
                setUser(decodeToken(token));
                navigate("/");
            }
        } catch (error: any) {
            setError(
                error?.response?.data?.error ||
                    error?.response?.data ||
                    error?.message ||
                    "Unknown error"
            );
        }
        setLoading(false);
    };

    const inputProps: TextFieldProps[] = [
        {
            label: "Class",
            type: "string",
            inputProps: { pattern: "[1-6][a-dA-D]" },
            onChange: (e) => {
                setClassName(e.target.value);
            },
            helperText: !class_name.match(/^[1-6][a-dA-D]$/)
                ? "Class must be 1A - 6D"
                : "",
        },
        {
            label: "Class Number",
            type: "number",
            inputProps: { min: 1, max: 36 },
            onChange: (e) => {
                setClassNumber(Number(e.target.value));
            },
            helperText: !(class_number >= 1 && class_number <= 36)
                ? "Class number must be 1 - 36"
                : "",
        },
        {
            label: "Email",
            type: "email",
            onChange: (e) => {
                setEmail(e.currentTarget.value);
            },
            inputProps: { format: "[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@cloud.qes.edu.hk" },
            helperText: !email.match(
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@cloud.qes.edu.hk$/
            )
                ? "Email must end with cloud.qes.edu.hk"
                : "",
        },
        {
            label: "Password",
            type: "password",
            onChange: (e) => {
                setPassword(e.currentTarget.value);
            },
            helperText: !password ? "Password must not be empty" : "",
        },
    ];

    return (
        <Box className={"flex align-center flex-dir-column mb40"}>
            <form
                onSubmit={handleSubmit}
                className={"flex flex-dir-column"}
                style={{ width: isSmallScreen ? "80vw" : "50vw" }}
            >
                <h2>Register</h2>
                {error && (
                    <Alert className={"mb20"} severity="error">
                        {error}
                    </Alert>
                )}
                {inputProps.map((props, index) => (
                    <TextField
                        key={index}
                        {...props}
                        className={"mb20"}
                        error={!!error}
                        disabled={loading}
                        variant={"outlined"}
                        required
                        fullWidth
                    />
                ))}
                <div className="flex justify-space-between align-center">
                    <Link className={"text-decoration-none"} to={"/users/signin"}>
                        <Button variant={"text"}>Sign in</Button>
                    </Link>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button type="submit" variant={"contained"}>
                            Register
                        </Button>
                    )}
                </div>
            </form>
        </Box>
    );
}
